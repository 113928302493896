@import "~@/erp/styles/variables/variables.scss";


















































































































































































































.num-total {
  padding-left: 15px;
  > .el-col {
    border: 1px solid #ededed;
    padding: 15px 20px;
    border-radius: 2px;
    h4 {
      font-size: 30px;
      span {
        font-size: 14px;
        font-weight: normal;
      }
    }
    p {
      margin-top: 5px;
    }
  }
  .el-col + .el-col {
    margin-left: 10px;
  }
}
::v-deep {
  .el-tabs__content {
    padding-left: 50px !important;
  }
}
