@import "~@/erp/styles/variables/variables.scss";














































































































































































































































































































































































































































































.pro-container {
  height: 100%;
  overflow: hidden;
  // overflow-y: auto;
  position: relative;
  overflow-y: hidden !important;

  .fix-msg-operate {
    h6 {
      font-size: 14px;
    }

    p {
      label {
        color: #999;
      }

      span {
        color: #000;
      }
    }
  }

  .tabs-content {
    padding: 8px 20px 0;
    height: calc(100% - 44px);
  }
}

::v-deep {
  .el-tabs--border-card {
    box-shadow: 0 0 0 0 rgb(0, 0, 0);
  }

  .el-tabs {
    height: 100%;
  }

  .el-tabs__header {
    margin: 0 0 20px;
  }

  .el-tabs__content {
    height: calc(100% - 59px);
    overflow-y: auto;
    // overflow-x: auto;
  }

  .el-page-header {
    margin: 0 !important;
  }
}

.page-header {
  padding: 0 20px;

  .page-header-line {
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 13px;
    padding-top: 4px;
  }
}
