@import "~@/erp/styles/variables/variables.scss";

































































































































































































































































































































































































.num-total {
  padding-left: 15px;
  > .el-col {
    border: 1px solid #ededed;
    padding: 15px 20px;
    border-radius: 2px;
    h4 {
      font-size: 30px;
      span {
        font-size: 14px;
        font-weight: normal;
      }
    }
    p {
      margin-top: 5px;
    }
  }
  .el-col + .el-col {
    margin-left: 10px;
  }
}
.circle-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  margin-left: 5px;
}
::v-deep {
  .el-tabs__content {
    padding-left: 50px !important;
  }
}
