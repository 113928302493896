@import "~@/erp/styles/variables/variables.scss";


































































































































































































































.msg-box {
  display: inline-block;
}
