@import "~@/erp/styles/variables/variables.scss";








































































































.pagination-container {
  background-color: #fff;
  padding: 20px 10px;
  text-align: right;
  padding-right: 60px;
}
.pagination-container.hidden {
  display: none;
}
.pagination-container.left {
  text-align: left;
}
