@import "~@/erp/styles/variables/variables.scss";






































































































































































































































































































::v-deep {
  .table-custom {
    height: 100% !important;
    .el-table {
      height: 100% !important;
    }
  }
  .table-fixed {
    height: 580px !important;
  }
}
