@import "~@/erp/styles/variables/variables.scss";




















































































.offer-remark {
  display: inline-block;
}
