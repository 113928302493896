@import "~@/erp/styles/variables/variables.scss";






















































































































































































































































































































































::v-deep {
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
}
