@import "~@/erp/styles/variables/variables.scss";
































































































































































































.bom {
  min-width: 1440px;
  z-index: 1;
  .bom-item {
    border: 1px solid #ebeef5;
    min-width: 420px;

    padding: 0px 0px 0px 10px;
    margin-bottom: 20px;
    .bom-name {
      height: 50px;
      line-height: 50px;
      padding: 0px 10px;
      display: flex;
      align-items: center;
    }
    ::v-deep {
      .el-timeline {
        height: 450px;
        overflow-y: scroll;
      }
      .el-timeline-item__node {
        border: 1px solid #2882ff;
        background: #fff;
      }
      .el-timeline-item__node--normal {
        left: 0;
      }
    }
  }
}
::v-deep .el-image__inner {
  height: 40px !important;
  width: 40px !important;
}
